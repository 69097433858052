






import { Component, Mixins, Prop } from 'vue-property-decorator'
import SelectOptionsMixin from "@/mixins/SelectOptionsMixin";
import ResolveFromCondition from "../ResolveFromCondition/ResolveFromCondition";
import ResolveFromRankCondition from "../ResolveFromRankCondition/ResolveFromRankCondition";
import ResolveFromApCondition from "../ResolveFromApCondition/ResolveFromApCondition";
import ResolveFromRepCondition from "../ResolveFromRepCondition/ResolveFromRepCondition";
import ResolveFromXpCondition from "../ResolveFromXpCondition/ResolveFromXpCondition";

@Component({})
export default class ResolveFromConditionValue extends Mixins(SelectOptionsMixin) {
  @Prop() model!: ResolveFromCondition

  get label() {

    const workTime = this.model.work_before ? this.$t('resolve_from_rank_work_before_true_title').toString() : this.$t('resolve_from_rank_work_before_false_title').toString()
    const value = this.model.value

    if (this.model instanceof ResolveFromRankCondition) {
      const rankLabel = this.ranksOptions().find(r => r.value === value)

      if (rankLabel) {
        if (rankLabel.value === 0) {
          return rankLabel.label
        } else if (rankLabel.value > 0) {
          return `${ workTime } ${ this.$t('resolve_from_rank_selected_rank_title', [ rankLabel.label ]).toString() }`
        } else {
          return value
        }
      } else {
        return value
      }
    } else if (this.model instanceof ResolveFromApCondition) {
      return `${ workTime } ${ value } ${ this.$t('resolve_from_ap_selected_title').toString() }`
    } else if (this.model instanceof ResolveFromRepCondition) {
      return `${ workTime } ${ value } ${ this.$t('resolve_from_rep_selected_title').toString() }`
    } else if (this.model instanceof ResolveFromXpCondition) {
      return `${ workTime } ${ value } ${ this.$t('resolve_from_xp_selected_title').toString() }`
    } else {
      return ''
    }
  }
}
